<template>
  <div class="result-banner-and-grid">
    <div class="modal" v-if="showInformationModal" data-bs-backdrop="static"
      ><div class="info"
        ><h4><strong>Information</strong></h4
        ><p v-if="languageInformationModal === 'de'">
          Ab sofort finden Sie die Kennzahlen auf Fondspreisebene in dem neuen
          Report FundAnalytics hier im Portal. Der neue Report ersetzt die
          frühere Seite 2 des Risikoberichts.
        </p>
        <p v-else
          >You can now find the key figures at fund price level in the new
          FundAnalytics report here on the portal. The new report replaces the
          former page 2 of the risk report.
        </p>
        <button class="button" @click="closeInformationModal()">Ok</button>
      </div></div
    >

    <banner-regression @input="toggleshowRowsInDiffOnly($event)" />
    <banner-standard
      :showExpRequest="isExpRequestActive"
      :showExpPDFRequest="isExpPDFRequestActive"
      :showIRSRequest="isIRSRequestActive"
      :showSecondaryGrid="isSecondaryGridActive"
      :nowrap="nowrap"
      @ToggleExpRequest="isExpRequestActive = !isExpRequestActive"
      @ToggleExpPDFRequest="isExpPDFRequestActive = !isExpPDFRequestActive"
      @ToggleSecondaryGrid="toggleSecondaryGrid"
      @triggerToggleNoWrap="nowrap = !nowrap"
    />
    <banner-exp-request
      v-if="showExpRequestBanner"
      :selectedRows="expSelectedRows"
      @triggerClearRowSelection="clearRowSelection()"
    />
    <banner-exp-pdf-request
      v-if="showExpPDFRequestBanner"
      :selectedRows="expSelectedRows"
      @triggerClearRowSelection="clearRowSelection()"
    />
    <banner-irs-request
      v-if="showIRSRequestBanner"
      :selectedRows="expSelectedRows"
      @triggerClearRowSelection="clearRowSelection()"
    />
    <qxp-grid-sel
      v-if="
        showExpRequestBanner || showExpPDFRequestBanner || showIRSRequestBanner
      "
      ref="qxpgridsel"
      :nowrap="nowrap"
      @selectedRows="onSelectedRows($event)"
    ></qxp-grid-sel>
    <qxp-grid-sel
      v-else-if="showSecondaryGrid"
      :class="{
        'qxpgrid-container-shrink':
          isSecondaryGridActive && isSecondaryLoadedOrLoading,
      }"
      :nowrap="nowrap"
      :singleRowSelectionMode="true"
      @selectedRows="onSelectedRowsSingleMode($event)"
    ></qxp-grid-sel>
    <qxp-grid-regression
      v-else-if="showReg"
      :showRowsInDiffOnly="showRowsInDiffOnly"
      :nowrap="nowrap"
    ></qxp-grid-regression>
    <qxp-grid-corr
      v-else-if="isWViewEligibleForCorrection"
      :nowrap="nowrap"
    ></qxp-grid-corr>
    <qxp-grid v-else :nowrap="nowrap"></qxp-grid>
    <banner-standard
      v-if="showSecondaryGrid"
      :nowrap="SecondaryNoWrap"
      :isSecondaryBanner="true"
      @triggerToggleNoWrap="SecondaryNoWrap = !SecondaryNoWrap"
    />

    <qxp-grid
      v-if="showSecondaryGrid && !isStoreBWViewEligibleForCorrection"
      :class="{
        'qxpgrid-container-shrink':
          isSecondaryGridActive && isSecondaryLoadedOrLoading,
      }"
      :useSecondaryStore="true"
      :nowrap="SecondaryNoWrap"
    ></qxp-grid>
    <qxp-grid-corr
      v-else-if="showSecondaryGrid && isStoreBWViewEligibleForCorrection"
      :class="{
        'qxpgrid-container-shrink':
          isSecondaryGridActive && isSecondaryLoadedOrLoading,
      }"
      :useSecondaryStore="true"
      :nowrap="SecondaryNoWrap"
    ></qxp-grid-corr>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QxpGrid from "@/components/datagrid/QxpGrid/QxpGrid";
import QxpGridSel from "@/components/datagrid/QxpGrid/QxpGridSel";
import QxpGridCorr from "@/components/datagrid/QxpGrid/QxpGridCorr";
import QxpGridRegression from "@/components/datagrid/QxpGrid/QxpGridRegression";
import BannerRegression from "@/components/datagrid/BannerRegression";
import BannerStandard from "@/components/datagrid/BannerStandard";
import BannerExpRequest from "@/components/datagrid/BannerExpRequest";
import BannerExpPDFRequest from "@/components/datagrid/BannerExpPDFRequest";
import BannerIRSRequest from "@/components/datagrid/BannerIRSRequest";
import {
  getDateForQuery,
  getUrlFromLink,
  isDrillDownInURL,
  getCol,
} from "@/components/wv_filter/WvQueryOption.js";
import { getCellValue } from "@/components/datagrid/QxpGrid/QxpFunctions.js";
import parser from "odata-parser";
import moment from "moment";

export default {
  data() {
    return {
      showRowsInDiffOnly: true,
      isExpRequestActive: false,
      isExpPDFRequestActive: true,
      isIRSRequestActive: true,
      isSecondaryGridActive: false,
      expSelectedRows: [],
      nowrap: false,
      SecondaryNoWrap: false,
      parser: parser,
      showInformationModal: false,
      languageInformationModal: "en",
      // SecondaryViewName: 'BACKTESTING_ALL_PVP'
    };
  },
  watch: {
    isSecondaryGridActive: function (value) {
      if (value) {
        if (this.$store.getters["wviewStoreB/isInfoLoaded"]) {
          let info = this.$store.getters["wviewStoreB/wvInfo"];
          if (info && info.hu && info.hu.WVName == this.SecondaryViewName) {
          } else {
            this.LoadSecondaryInfo();
          }
        } else {
          this.LoadSecondaryInfo();
        }
      } else {
        this.$store.dispatch("wviewStoreB/CLEAR");
      }
    },
    wvInfo: function (value) {
      this.isSecondaryGridActive = false;
      this.isExpRequestActive = false;
    },
  },
  computed: {
    ...mapGetters({
      isInfoLoaded: "wviewStore/isInfoLoaded",
      wvInfo: "wviewStore/wvInfo",
      isStoreBInfoLoading: "wviewStoreB/isInfoLoading",
      isStoreBInfoLoaded: "wviewStoreB/isInfoLoaded",
      isStoreBDataLoaded: "wviewStoreB/isDataLoaded",
      isStoreBDataLoading: "wviewStoreB/isDataLoading",
      isStoreBWViewEligibleForCorrection:
        "wviewStoreB/isWViewEligibleForCorrection",
      isWViewEligibleForExpPDFRequest:
        "wviewStore/isWViewEligibleForExpPDFRequest",
      isWViewEligibleForExpRequest: "wviewStore/isWViewEligibleForExpRequest",
      isWViewEligibleForIRSRequest: "wviewStore/isWViewEligibleForIRSRequest",
      isWViewEligibleForCorrection: "wviewStore/isWViewEligibleForCorrection",
      SecondaryViewName: "wviewStore/SecondaryViewName",
      SecondaryFilterMapping: "wviewStore/SecondaryFilterMapping",
      showReg: "wviewStore/showReg",
    }),
    showExpRequestBanner() {
      if (!this.isInfoLoaded) {
        this.isExpRequestActive = false; //reset to false if info loading or cleared (!isInfoLoaded)
      }
      return this.isWViewEligibleForExpRequest && this.isExpRequestActive;
    },
    showExpPDFRequestBanner() {
      if (!this.isInfoLoaded) {
        return false; //reset to false if info loading or cleared (!isInfoLoaded)
      }
      return this.isWViewEligibleForExpPDFRequest && this.isExpPDFRequestActive;
    },
    showIRSRequestBanner() {
      if (!this.isInfoLoaded) {
        return false; //reset to false if info loading or cleared (!isInfoLoaded)
      }
      return this.isWViewEligibleForIRSRequest && this.isIRSRequestActive;
    },
    showSecondaryGrid() {
      return this.isSecondaryGridActive;
    },
    isSecondaryLoadedOrLoading() {
      return (
        (this.isStoreBInfoLoaded || this.isStoreBDataLoading) &&
        (this.isStoreBDataLoaded || this.isStoreBDataLoading)
      );
    },
  },
  methods: {
    checkForInformationModal() {
      // this.$nextTick(function () {
      //   setTimeout(() => {
      //     if (
      //       this.isInfoLoaded &&
      //       this.wvInfo &&
      //       this.wvInfo.hu &&
      //       this.wvInfo.hu.WVName &&
      //       this.wvInfo.hu.WVName === "KUNDEN_PDFList_Risikobericht"
      //     ) {
      //       const body = document.body;
      //       if (body) {
      //         body.style.pointerEvents = "none";
      //       }
      //       var navigatorlocale =
      //         window.navigator.userLanguage || window.navigator.language;
      //       if (navigatorlocale) {
      //         let language = navigatorlocale.split("-");
      //         this.languageInformationModal = language[0] || "en";
      //       }
      //       this.showInformationModal = false;
      //     }
      //   }, 1000);
      // });
    },
    closeInformationModal() {
      this.showInformationModal = false;
      const body = document.body;
      if (body) {
        body.style.pointerEvents = "auto";
      }
    },
    getCellValue: getCellValue,
    getCol: getCol,
    toggleSecondaryGrid() {
      this.isSecondaryGridActive = !this.isSecondaryGridActive;
      this.nowrap = true;
      this.SecondaryNoWrap = true;
    },
    toggleshowRowsInDiffOnly(event) {
      this.showRowsInDiffOnly = event;
    },
    onSelectedRows(selectedRows) {
      if (selectedRows) {
        this.expSelectedRows = selectedRows;
      } else {
        this.expSelectedRows = [];
      }
    },
    onSelectedRowsSingleMode(selectedRows) {
      // console.log(selectedRows);
      if (selectedRows) {
        this.expSelectedRows = selectedRows;
      } else {
        this.expSelectedRows = [];
      }
      if (this.isSecondaryGridActive) {
        this.LoadSecondaryData();
      }
    },
    clearRowSelection() {
      this.$refs.qxpgridsel.clearRowSelectionAndReset();
    },
    LoadSecondaryInfo() {
      if (!!this.SecondaryViewName) {
        this.$store.dispatch("wviewStoreB/LOAD_INFO", {
          vwiewName: this.SecondaryViewName,
        });
      }
    },
    getApiFilterFromMapping(row0) {
      let filters = [];
      let info = this.wvInfo;
      this.SecondaryFilterMapping.forEach((mapping) => {
        if (row0.hasOwnProperty(mapping[0])) {
          if (
            !!info &&
            !!info.hu &&
            !!info.hu.colForList &&
            info.hu.colForList.hasOwnProperty(mapping[0]) &&
            info.hu.colForList[mapping[0]].ColType == "date"
          ) {
            filters.push(
              `(${mapping[1]} eq ${getDateForQuery(row0[mapping[0]])})`
            );
          } else {
            filters.push(`(${mapping[1]} eq '${row0[mapping[0]]}')`);
          }
        } else if (mapping[0] == "additionalfilterforsecondary") {
          filters.push(mapping[1]);
        }
      });
      console.log(filters.join(" and "));
      return filters;
    },
    getApiWviewFromRow(row0) {
      let url = getUrlFromLink(row0.drilldownType);
      if (!url) return;
      return url.hostname;
    },
    getApiFilterFromRow(row0) {
      let info = this.wvInfo;
      let url = getUrlFromLink(row0.drilldownType);
      if (!url) return;
      let params = new URLSearchParams(url.search.slice(1));
      for (var key of params.keys()) {
        if (key.toLowerCase() == "$filter") {
          var filter = "$filter=" + params.get(key);
          console.debug(filter);
        }
      }
      // odata_parser doesnt know 'like'
      filter = filter
        .replaceAll(">", ">'")
        .replaceAll("%s<", "'<")
        .replaceAll("%s", "'%s'")
        .replaceAll("''%s''", "'%s'")
        .replaceAll(" like ", " eq ")
        .replaceAll(" not like ", " ne ");

      var result = parser.parse(filter);
      if (!result.error) {
        return this.filterToString(info, row0, result.$filter);
      }
    },
    filterToString(info, row0, odata_filter) {
      switch (odata_filter.type) {
        case "and":
        case "or":
          // console.debug(odata_filter.type);
          var ret = "";
          ret = `${ret}${this.filterToString(info, row0, odata_filter.left)}`;
          ret = `${ret} ${odata_filter.type} `;
          ret = `${ret}${this.filterToString(info, row0, odata_filter.right)}`;
          return ret;
          break;
        case "eq":
        case "ne":
          // console.debug(odata_filter.type);
          var ret = "";
          ret = `(${ret}${odata_filter.left.name}`;
          if (odata_filter.right.value == "%s") {
            // handles value from col
            ret = `${ret} ${odata_filter.type} `;
            var col = this.getCol(this.wvInfo, odata_filter.left.name);
            if (!col) {
              // dirty manual mapping
              switch (odata_filter.left.name) {
                case "referenceDate":
                case "date":
                case "cre_date":
                case "risk_driver_date":
                  col = this.getCol(this.wvInfo, "refDate");
                  break;
                case "refFundName":
                case "OpusNr":
                  col = this.getCol(this.wvInfo, "book");
                  break;
              }
            }
            var valueFromRow = this.getCellValue(row0, col);
            var quote = "";
            if (col.ColType == "name" || col.ColType == "text") {
              quote = "'";
            }
            ret = `${ret}${quote}${valueFromRow}${quote})`;
          } else if (
            typeof odata_filter.right.value == "string" &&
            (odata_filter.right.value.startsWith("%") ||
              odata_filter.right.value.endsWith("%"))
          ) {
            // handles like/not like
            ret = `${ret} ${odata_filter.type == "eq" ? "like" : "not like"} `;
            ret = `${ret}'${odata_filter.right.value.replaceAll("%%", "%")}')`;
          } else {
            // handles plain vanilla
            ret = `${ret} ${odata_filter.type} `;
            if (typeof odata_filter.right.value == "string") {
              ret = `${ret}'${odata_filter.right.value}')`;
            } else {
              ret = `${ret}${odata_filter.right.value})`;
            }
          }

          return ret;
          break;
        case "ge":
        case "gt":
        case "le":
        case "lt":
          // console.debug(odata_filter.type);
          var ret = "";
          ret = `(${ret}${odata_filter.left.name}`;
          ret = `${ret} ${odata_filter.type} `;
          if (odata_filter.right.value == "%s") {
            var col = this.getCol(this.wvInfo, odata_filter.left.name);
            var valueFromRow = this.getCellValue(row0, col);
            ret = `${ret}${valueFromRow})`;
          } else if (odata_filter.right.value.startsWith("<")) {
            var sdiff = odata_filter.right.value
              .replaceAll("<", "")
              .replaceAll(">", "");
            if (odata_filter.left.name.includes("date")) {
              var val = moment();
              val.subtract(sdiff, "d");
              ret = `${ret}${val.format("YYYY-MM-DD")})`;
            } else {
              ret = `${ret}${sdiff})`;
            }
          } else {
            ret = `${ret}${odata_filter.right.value})`;
          }
          return ret;
          break;
        default:
          console.debug("unknown odata operator: " + odata_filter.type);
      }
    },
    LoadSecondaryData() {
      if (this.expSelectedRows && this.expSelectedRows.length > 0) {
        const row0 = this.expSelectedRows[0];
        if (isDrillDownInURL(this.$route.fullPath)) {
          var drilldownwviewname = this.getApiWviewFromRow(row0);
          if (drilldownwviewname.toUpperCase() == "FOND_RISIKOKENNZAHLEN2") {
            drilldownwviewname = "FOND_RISIKOKENNZAHLEN";
          }
          var filterstring = this.getApiFilterFromRow(row0);
          console.debug(filterstring);
          if (!!filterstring) {
            var queryParams = {
              $filter: filterstring,
              byPassIsRequest: true,
            };
            this.$store.dispatch("wviewStoreB/LOAD_INFO", {
              vwiewName: drilldownwviewname,
            });
            this.$store.dispatch("wviewStoreB/LOAD_DATA", {
              vwiewName: drilldownwviewname,
              queryParams: queryParams,
            });
          }
        } else {
          let filters = [];
          filters = this.getApiFilterFromMapping(row0);
          if (filters.length > 0) {
            var queryParams = {
              $filter: filters.join(" and "),
              byPassIsRequest: true,
            };
            // make sure to load info, if not already here (f.ex after CLEAR on Primary Reload)
            if (!this.$store.getters["wviewStoreB/isInfoLoaded"]) {
              this.$store.dispatch("wviewStoreB/LOAD_INFO", {
                vwiewName: this.SecondaryViewName,
              });
            }
            this.$store.dispatch("wviewStoreB/LOAD_DATA", {
              vwiewName: this.SecondaryViewName,
              queryParams: queryParams,
            });
          }
        }
      }
    },
  },
  mounted() {
    this.checkForInformationModal();
  },
  components: {
    BannerRegression,
    BannerStandard,
    BannerExpRequest,
    "banner-irs-request": BannerIRSRequest,
    "banner-exp-pdf-request": BannerExpPDFRequest,
    QxpGrid,
    QxpGridSel,
    QxpGridCorr,
    QxpGridRegression,
  },
};
</script>
